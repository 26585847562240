<template>
  <a-modal
    width="495px"
    class="project-onboarding-modal"
    :dialog-style="{ display: 'none' }"
    :footer="null"
    :visible="true"
    :closable="true"
    @cancel="onExit">
    <h1 class="flex align-center justify-center txt-30 txt-bold txt-font-din-medium">
      {{ $t('components.titles.skipSurveyPilotJustification') }}
    </h1>
    <p class="txt-16">
      {{ $t('components.description.pleaseProvideNotPilotingSurvey') }}
    </p>
    <a-textarea
      v-model="justification"
      :placeholder="$t('components.description.justificationPlaceholder')"
      :required="true"
      size="large"/>
    <div class="mt-24 flex justify-space-between">
      <a-button
        class="w200"
        type="primary"
        size="large"
        ghost
        @click="onExit">
        {{ $t('values.cancel') }}
      </a-button>
      <a-button
        class="w200"
        type="primary"
        size="large"
        :disabled="justification === ''"
        @click="onComplete(justification)">
        {{ $t('values.done') }}
      </a-button>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'ProjectDashboardPilotSkipJustification',
  props: {
    onExit: {
      type: Function,
      required: true
    },
    onComplete: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      justification: ''
    };
  }
};
</script>

<style lang="scss">
</style>
